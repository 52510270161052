import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import ListNewDevelopments from "@components/new-developments/List"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "new-developments.getAll",
  query: 'crm_provider=property_uploader&deleteAttempt=0'
}

const ListNewDevelopmentsPage = (props) => (
  <>
    <Seo title="New Developments List" />
    <div className="main-wrapper">
      <Sidebar menuActive="new-developments" />
      <ListNewDevelopments
        addBtnText="New Development"
        menu="New Developments"
        parentPath="new-developments"
        pageType="List"
        searchBoxSuffixText="New Development"
        additionalParams={AdditionalParams}
      />
    </div>
  </>
)

export default ListNewDevelopmentsPage;
